
function Success() {
    return (
        <>
            <div class="breadcumb-area d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breacumb-content">
                                <div class="breadcumb-title">
                                    <h1>Kontakt</h1>
                                </div>
                                <div class="breadcumb-content-text">
                                    <a href="/"> START<i class="fa fa-angle-right"></i><span>Kontakt</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="single-contact-box">
                                <div class="section-title">
                                    <div class="sub-title">
                                        <h5>Wysyłka wiadomości<span></span></h5>
                                        {/* <h2>Chcesz porozmawiać o swoim projekcie?</h2> */}
                                    </div>
                                </div>
                                <form action="/" method="get">
                                    <div style={{ textAlign: 'center' }}>
                                        <h3 id="status" >Wiadomości została do nas wysłana. Odpowiemy najszybciej jak to możliwe.</h3>
                                        <div class="form-button">
                                            <button type="submit">Wróć do strony <i class="flaticon-right-arrow"></i></button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default Success;
