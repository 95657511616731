
function Navigation({specialClass}) {
    return (
        <ul className={specialClass}>
            <li><a href="/">Start </a>
            </li>
            <li><a href="/services">Usługi</a></li>
            {/* <li><a href="/about-us">O firmie</a></li> */}
            <li><a href="/contact">Kontakt</a></li>
        </ul>
    );
}

export default Navigation;
