function Footer() {
    return (
        <div class="footer-area">
            <div class="container">
                {/* <div class="row fbg">
                    <div class="col-lg-6 col-md-6">
                        <div class="subscrib">
                            <h2>Subscribe Our Newsletter To <br /> Get More Updates</h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <form action="https://formspree.io/f/myyleorq" method="POST" id="dreamit-form">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form_box">
                                        <input type="text" name="Email address" placeholder="Email address" />
                                    </div>
                                </div>
                                <div class="form-button">
                                    <button type="submit">sign up</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> */}
                <div class="row pad">
                    <div class="col-lg-3 col-md-6">
                        <div class="footer-box">
                            <div class="footer-logo">
                                <img src="assets/images/logo_biale_text.png" alt="Logo" />
                            </div>
                            <div class="footer-text">
                                <p>Firma programistyczna oferująca indywidualnie systemy CRM, CMS oraz strony www.</p>
                            </div>
                            <div class="footer-icon">
                                <ul>
                                    <li><a href="https://www.facebook.com/AMCompPL" target="_blank"><i class="fa-brands fa-square-facebook"></i></a></li>
                                    <li><a href="https://www.instagram.com/amcomppl/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a></li>
                                    {/* <li><a href="#"><i class="fa-brands fa-linkedin"></i></a></li> */}
                                    <li><a href="https://github.com/adrianmarpl" target="_blank"><i class="fa-brands fa-square-github"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6">
                        {/* <div class="footer-box">
                            <div class="footer-title">
                                <h5>Important Links</h5>
                            </div>
                            <div class="footers-icon">
                                <ul>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Interface Design</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>SEO Optimiser</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Digital Marketing</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Graphics Design</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Web Development</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div class="col-lg-2 col-md-6">
                        {/* <div class="footer-box">
                            <div class="footer-title">
                                <h5>Quick Links</h5>
                            </div>
                            <div class="footers-icon">
                                <ul>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Latest News</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>About Us</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>How To Work</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Privacy Policy</a></li>
                                    <li><a href="#"><i class="flaticon flaticon-right-arrow"></i>Contact Us</a></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="footer-box">
                            <div class="footer-title">
                                <h5>Informacje</h5>
                            </div>
                            {/* <div class="social-icon">
                                <ul>
                                    <li><i class="fas fa-map-marker-alt"></i></li>
                                </ul>
                            </div>
                            <div class="social-tex">
                                <span>Address</span>
                                <p> 55 Main Street, New York</p>
                            </div> */}
                            <div class="social-icon">
                                <ul>
                                    <li><i class="far fa-envelope-open"></i></li>
                                </ul>
                            </div>
                            <div class="social-tex">
                                <span>Email</span>
                                <p>adrian.marjanski@amcomp.pl</p>
                            </div>
                            <div class="social-icon">
                                <ul>
                                    <li><i class="fa fa-phone"></i></li>
                                </ul>
                            </div>
                            <div class="social-tex">
                                <span>Telefon</span>
                                <p>+48 796-308-424</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="copy-right-area">
                    <span>© 2023 AMComp . All Rights Reserved.</span>
                </div>
            </div>
        </div>
    )
}
export default Footer;