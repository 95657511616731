
function Home() {
    return (
        <div>
            <div>

                <div class="slider-area d-flex align-items-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-slider-box">
                                    <div class="slider-content">
                                        <div class="slider-title">
                                            <h1>Nowoczesne rozwiązania IT <br /> dla Twojego biznesu</h1>
                                            <p>Poznaj możliwości rozwoju i wsparcia swojego biznesu...</p>
                                        </div>
                                    </div>
                                    {/* <div class="slider-button">
                                        <a href="#">Rozpocznij <i class="flaticon-right-arrow"></i></a>
                                    </div> */}
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="slider-thumb">
                                    <img src="assets/images/hero2.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="service-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <div class="main-title">
                                        <h1>01</h1>
                                    </div>
                                    <div class="sub-title">
                                        <h5>Co oferujemy?<span></span></h5>
                                        {/* <h2>What we provide for <br /> growth business</h2> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-box">
                                    <div class="service-content">
                                        <div class="service-ico">
                                            <i class="flaticon flaticon-bar-chart"></i>
                                        </div>
                                        <div class="service-title">
                                            <h3>Indywidualne podejście</h3>
                                            <p>Do każdego projektu podchodzimy indywidualnie przygotowując rozwiązania dopasowane do specjalistycznych wymagań.</p>
                                        </div>
                                    </div>
                                    {/* <div class="service-button">
                                    <a href="#">Read More <i class="flaticon-right-arrow"></i></a>
                                </div> */}
                                    <div class="shap-ing">
                                        <img src="assets/images/dots.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-box">
                                    <div class="service-content">
                                        <div class="service-icos">
                                            <i class="flaticon flaticon-advertising"></i>
                                        </div>
                                        <div class="service-title">
                                            <h3>Doświadczenie</h3>
                                            <p>Wieloletnie doświedczenie zdobyte przy realizacji projektów z różnych branż np. farmaceutycznych, telekomunikacyjnych, e-commerce</p>
                                        </div>
                                    </div>
                                    {/* <div class="service-button">
                                    <a href="#">Read More <i class="flaticon-right-arrow"></i></a>
                                </div> */}
                                    <div class="shap-ing">
                                        <img src="assets/images/dots.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="single-service-box">
                                    <div class="service-content">
                                        <div class="service-icons">
                                            <i class="flaticon flaticon-email"></i>
                                        </div>
                                        <div class="service-title">
                                            <h3>Innowacyjność</h3>
                                            <p>Projekty realizujemy o najnowsze rozwiązania programistyczne, które pozwalają innowacyjnie zrealizować najtrudniejsze problemy.</p>
                                        </div>
                                    </div>
                                    {/* <div class="service-button">
                                    <a href="#">Read More <i class="flaticon-right-arrow"></i></a>
                                </div> */}
                                    <div class="shap-ing">
                                        <img src="assets/images/dots.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="about-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="about-thumb faq-img-animation">
                                    <img src="assets/images/img5.png" alt="about img" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="section-title">
                                    <div class="main-title">
                                        <h1>02</h1>
                                    </div>
                                    <div class="sub-title">
                                        <h5>O firmie</h5>
                                        <h2>Doświadczony zespół<br /> do rozwiązywania problemów</h2>
                                        <p>Odpowiednie zidentyfikowanie wymagań i opracowanie projektu jest niezwykle ważne aby Twój biznes mógł się rozwijać. Nasz zespół pomoże Ci w tym przygotowując dopasowany system.</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="about-box">
                                            <div class="about-icon">
                                                <i class="flaticon flaticon-money-4"></i>
                                            </div>
                                            <div class="about-title">
                                                <h2>CRM dostosowany <br /> do indywidualnych wymagań</h2>
                                                <p>Obserwuj jak rozwija się Twój biznes na dopasowanych raportach</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="about-box">
                                            <div class="about-icon">
                                                <i class="flaticon flaticon-project-management"></i>
                                            </div>
                                            <div class="about-title">
                                                <h2>Indywidualne <br /> prowadzenie projektu</h2>
                                                <p>Zdaj się na nasze doświedczenie i Innowacyjność w prowadzeniu projektu</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="about-button">
                                <a href="#">Read More <i class="flaticon-right-arrow"></i></a>
                            </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Why-choos-us-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="choos-single-box">
                                    <div class="section-title">
                                        <div class="main-title">
                                            <h1>03</h1>
                                        </div>
                                        <div class="sub-title">
                                            <h5>Co robimy? </h5>
                                            <h2>Zaawansowane systemy</h2>
                                            <p>Każdy nasz system, strona i aplikacja mobilna są dostosowane do Twoich specjalistycznych potrzeb.</p>
                                            {/* <p>Odpowiednio dostosowany system przyczynia się do rozwoju biznesu</p>     */}
                                            {/* <p>Twój nowy system może być zintegrowany z istniejącymi w Twojej Firmie.</p> */}
                                        </div>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Systemy CRM</h3>
                                        <p>Zarządzaj firmą poprzez system posiadający warstwę użytkownika i administratora</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Strony www</h3>
                                        <p>Zaprezentuj swoja firmę w Internecie i Social Mediach. Strony są dostosowane pod SEO.</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Aplikacja mobilne</h3>
                                        <p>Idealnym uzupełnieniem systemu lub strony jest aplikacja mobilna.</p>
                                    </div>
                                    {/* <div class="choose-button">
                                    <a href="#">Learn More <i class="flaticon-right-arrow"></i></a>
                                </div> */}
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="choos-single-box">
                                    <div class="choos-thumb choos-img-animation">
                                        <img src="assets/images/img6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="portfolio-area">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-3 col-md-6">
                                <div class="portfolio-box">
                                    <div class="portfolio-thumb">
                                        <img src="assets/projects/dashboard.png" alt="" />
                                    </div>
                                    <div class="portfolio-title">
                                        <h3><a href="#">Dashboard</a></h3>
                                        <p>System webowy</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="portfolio-box">
                                    <div class="portfolio-thumb">
                                        <img src="assets/projects/shop.png" alt="" />
                                    </div>
                                    <div class="portfolio-title">
                                        <h3><a href="#">Admin panel</a></h3>
                                        <p>Zarządzanie stroną lub sklepem</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="portfolio-box">
                                    <div class="portfolio-thumb">
                                        <img src="assets/projects/appmobile.jpg" alt="" />
                                    </div>
                                    <div class="portfolio-title">
                                        <h3><a href="#">Aplikacje mobilne</a></h3>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            {/* <div class="col-lg-3 col-md-6">
                            <div class="portfolio-box">
                                <div class="portfolio-thumb">
                                    <img src="assets/images/pf4.png" alt="" />
                                </div>
                                <div class="portfolio-title">
                                    <h3><a href="#">Dashboard UI Design</a></h3>
                                    <p>Creative Works</p>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </div>
                </div>

                <div class="counter-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-counters-box">
                                    <div class="counter-thumb counter-img-animation">
                                        <img src="assets/images/img7.png" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="row">
                                    <div class="sub-title">
                                        <h5>Zajmujemy się również </h5>
                                        <h2>Inne usługi które oferujemy</h2>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-counter-box">
                                            <div class="counter-content">
                                                <div class="counter-icon">
                                                    <i class="flaticon flaticon-team"></i>
                                                </div>
                                                <div class="counter-title">
                                                    {/* <h1><span class="aa">856</span><span>+</span></h1> */}
                                                    <h5>Administracja systemami</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-counter-box">
                                            <div class="counter-content">
                                                <div class="counter-icon">
                                                    <i class="flaticon flaticon-suitcase"></i>
                                                </div>
                                                <div class="counter-title">
                                                    {/* <h1><span class="aa">856</span><span>+</span></h1> */}
                                                    <h5>Wsparcie IT</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-counter-box">
                                            <div class="counter-content">
                                                <div class="counter-icon">
                                                    <i class="flaticon flaticon-pen"></i>
                                                </div>
                                                <div class="counter-title">
                                                    {/* <h1><span class="aa">235</span><span>+</span></h1> */}
                                                    <h5>Audyt systemów</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="single-counter-box">
                                            <div class="counter-content">
                                                <div class="counter-icon">
                                                    <i class="flaticon flaticon-advertising"></i>
                                                </div>
                                                <div class="counter-title">
                                                    {/* <h1><span class="aa">785</span><span>+</span></h1> */}
                                                    <h5>Doradztwo projektowe</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div class="team-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <div class="main-title">
                                    <h1>05</h1>
                                </div>
                                <div class="sub-title">
                                    <h5>Meet Our Team<span></span></h5>
                                    <h2>We Have Lot's Of Experience <br /> Team Members</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team-single-box">
                                <div class="team-thumb">
                                    <img src="assets/images/t1.jpg" alt="" />
                                </div>
                                <div class="team-title">
                                    <h3><a href="#">John M. Anthony</a></h3>
                                    <span>Web Developer</span>
                                </div>
                                <div class="team-icon">
                                    <ul>
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-single-box">
                                <div class="team-thumb">
                                    <img src="assets/images/t2.jpg" alt="" />
                                </div>
                                <div class="team-title">
                                    <h3><a href="#">Sharon M. Sims</a></h3>
                                    <span>Web Developer</span>
                                </div>
                                <div class="team-icon">
                                    <ul>
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-single-box">
                                <div class="team-thumb">
                                    <img src="assets/images/t3.jpg" alt="" />
                                </div>
                                <div class="team-title">
                                    <h3><a href="#">Wanda J. Rosas</a></h3>
                                    <span>Web Developer</span>
                                </div>
                                <div class="team-icon">
                                    <ul>
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team-single-box">
                                <div class="team-thumb">
                                    <img src="assets/images/t4.jpg" alt="" />
                                </div>
                                <div class="team-title">
                                    <h3><a href="#">Kristie H. Austin</a></h3>
                                    <span>Web Developer</span>
                                </div>
                                <div class="team-icon">
                                    <ul>
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                {/* <div class="faq-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-single-box">
                                <div class="section-title">
                                    <div class="main-title">
                                        <h1>06</h1>
                                    </div>
                                    <div class="sub-title">
                                        <h5>Have Any Questions ? </h5>
                                        <h2>Freequently Asked <br /> Questions</h2>
                                    </div>
                                </div>
                                <ul class="accordion">
                                    <li>
                                        <a>01. Dolor sit amet, consectetur adipiscing elit, sed do?</a>
                                        <p>Consectetur adipiscing elit sed eiusmod tempor indu labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida commodo viverra maecenas</p>
                                    </li>
                                    <li>
                                        <a>02. Dolor sit amet, consectetur adipiscing elit, sed do?</a>
                                        <p>Consectetur adipiscing elit sed eiusmod tempor indu labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida commodo viverra maecenas</p>
                                    </li>
                                    <li>
                                        <a>03. Dolor sit amet, consectetur adipiscing elit, sed do?</a>
                                        <p>Consectetur adipiscing elit sed eiusmod tempor indu labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida commodo viverra maecenas</p>
                                    </li>
                                    <li>
                                        <a>04. Dolor sit amet, consectetur adipiscing elit, sed do?</a>
                                        <p>Consectetur adipiscing elit sed eiusmod tempor indu labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida commodo viverra maecenas</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="faq-single-box">
                                <div class="faq-thumb faq-img-animation">
                                    <img src="assets/images/img8.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                {/* <div class="pricing-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="section-title">
                                <div class="main-title">
                                </div>
                                <div class="sub-title">
                                    <h5>Pricing Package<span></span></h5>
                                    <h2>We Provide Awesome <br /> Pricing Package</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="single-pricing-box">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="pricing-left">
                                            <h3>Basic Plan</h3>
                                            <p>$ <span>13.76</span></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricing-center">
                                            <ul>
                                                <li><i class="fas fa-check"></i> Product Design</li>
                                                <li><i class="fas fa-check"></i> Web Development</li>
                                                <li><i class="fas fa-check"></i> Free Domain Hosting</li>
                                                <li><i class="fas fa-check"></i> Premium Services</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricing-button">
                                            <a href="#">Choose plan <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="single-pricings-boxs">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="pricing-left">
                                            <h3>Standard Plan</h3>
                                            <p>$ <span>53.76</span></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricing-center">
                                            <ul>
                                                <li><i class="fas fa-check"></i> Product Design</li>
                                                <li><i class="fas fa-check"></i> Web Development</li>
                                                <li><i class="fas fa-check"></i> Free Domain Hosting</li>
                                                <li><i class="fas fa-check"></i> Premium Services</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="pricing-button">
                                            <a href="#">Choose plan <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="single-pricing-box">
                                <div class="row">
                                    <div class="col-lg-4 col-md-6">
                                        <div class="pricing-left">
                                            <h3>Premium Plan</h3>
                                            <p>$ <span>93.6</span></p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="pricing-center">
                                            <ul>
                                                <li><i class="fas fa-check"></i> Product Design</li>
                                                <li><i class="fas fa-check"></i> Web Development</li>
                                                <li><i class="fas fa-check"></i> Free Domain Hosting</li>
                                                <li><i class="fas fa-check"></i> Premium Services</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-6">
                                        <div class="pricing-button">
                                            <a href="#">Choose plan <i class="fas fa-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

                {/* <div class="blog-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title">
                                    <div class="main-title">
                                        <h1>08</h1>
                                    </div>
                                    <div class="sub-title">
                                        <h5>Wiadomości<span></span></h5>
                                        <h2>Najnowsze posty</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6">
                                <div class="blog-single-box">
                                    <div class="blog-thumb">
                                        <img src="assets/images/blog2.png" alt="" />
                                    </div>
                                    <div class="blog-title">
                                        <span><i class="far fa-calendar-alt"></i> 15.01.2023</span>
                                        <h2><a href="#">Nowe technologie jak pomagają?</a></h2>
                                        <p>W IT nie ma przerwy..zawsze powstaje coś nowego.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="blog-single-box">
                                    <div class="blog-thumb">
                                        <img src="assets/images/blog3.png" alt="" />
                                    </div>
                                    <div class="blog-title">
                                        <span><i class="far fa-calendar-alt"></i> 13.01.2023</span>
                                        <h2><a href="#">Indywidualne podejście a sukces w biznesie</a></h2>
                                        <p>Czy wiesz że indywidualnie podejście do nowego systemu pomaga osiągnąć zamierzone cele w biznesie? </p>
                                      
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                                <div class="blog-single-box">
                                    <div class="blog-thumb">
                                        <img src="assets/images/blog1.png" alt="" />
                                    </div>
                                    <div class="blog-title">
                                        <span><i class="far fa-calendar-alt"></i> 12.01.2023</span>
                                        <h2><a href="#">Nowa odsłona strony</a></h2>
                                        <p>Witaj na nowej odsłonie strony firmowej AMComp</p>
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>);
}

export default Home;
