import {
    BrowserRouter,
    Route,
    Routes,
} from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import Services from "./Services";
import Success from "./Success";



function Root() {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/success" element={<Success />} />
                </Routes>
            </BrowserRouter>
        </>)
}
export default Root;
