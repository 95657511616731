
function Services() {
    return (
        <div>
            <div>
                <div class="breadcumb-area d-flex align-items-center">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="breacumb-content">
                                    <div class="breadcumb-title">
                                        <h1>Usługi</h1>
                                    </div>
                                    <div class="breadcumb-content-text">
                                        <a href="/"> START<i class="fa fa-angle-right"></i><span>Usługi</span></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="about-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="about-thumb faq-img-animation">
                                    <img src="assets/projects/dashboard.png" alt="CRM" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">

                                <div class="choos-single-box">
                                    <div class="section-title">
                                        <div class="main-title">
                                            <h1>01</h1>
                                        </div>
                                        <div class="sub-title">
                                            <h5>-</h5>
                                            <h2>CRM</h2>
                                            <p>System do zarządania Twoją Firmą pozwoli Ci na bieżąco monitorować interesujące Ciebie obszary Twojego biznesu.</p>
                                        </div>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Automatyzacja procesów</h3>
                                        <p>Zautomatyzuj powtarzalne procesy w Twoim przedsiębiorstwie i zaoszczędź czas.</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Baza klientów</h3>
                                        <p>Przeglądaj i zarządzaj bazą klientów w jednym miejscu. </p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Raporty dla managerów</h3>
                                        <p>Analizuj aktywność Twoich pracowników poprzez dostosowane raporty.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="Why-choos-us-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="choos-single-box">
                                    <div class="section-title">
                                        <div class="main-title">
                                            <h1>02</h1>
                                        </div>
                                        <div class="sub-title">
                                            <h5>- </h5>
                                            <h2>CMS</h2>
                                            <p>System do zarządzania kontentem strony www, sklepu internetowego lub zaawansowanego portalu.</p>
                                            {/* <p>Odpowiednio dostosowany system przyczynia się do rozwoju biznesu</p>     */}
                                            {/* <p>Twój nowy system może być zintegrowany z istniejącymi w Twojej Firmie.</p> */}
                                        </div>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Strona www</h3>
                                        <p>Twórz podstrony, zmieniaj układ i wszystko zgodnie z SEO.</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Sklep internetowy</h3>
                                        <p>Zarządzaj produktami, bazą klientów i zamówieniami</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Portal</h3>
                                        <p>Twórz artykuły, zmieniaj szczegóły elementów i układ portalu</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="choos-single-box">
                                    <div class="choos-thumb choos-img-animation">
                                        <img src="assets/projects/shop.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="about-area">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="about-thumb faq-img-animation">
                                    <img src="assets/projects/appmobile.jpg" alt="CRM" />
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">

                                <div class="choos-single-box">
                                    <div class="section-title">
                                        <div class="main-title">
                                            <h1>03</h1>
                                        </div>
                                        <div class="sub-title">
                                            <h5>-</h5>
                                            <h2>Aplikacje mobilne</h2>
                                            <p>Idealne uzupełnienie systemu CRM albo Twojej strony www.</p>
                                            {/* <p>Odpowiednio dostosowany system przyczynia się do rozwoju biznesu</p>     */}
                                            {/* <p>Twój nowy system może być zintegrowany z istniejącymi w Twojej Firmie.</p> */}
                                        </div>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Integracja z CRM</h3>
                                        <p>Zarządzaj firmą przez smartfon.</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Wirtualna prezentacja</h3>
                                        <p>Zaprezentuj materiały reklamowe przez tablet.</p>
                                    </div>
                                    <div class="choose-icon">
                                        <i class="fa fa-check"></i>
                                    </div>
                                    <div class="choose-title">
                                        <h3>Zarządzanie stroną</h3>
                                        <p>Twórz artykuły, przeglądaj ruch na stronie zdalnie poprzez smartfon</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default Services;
