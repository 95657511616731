import logo from './logo.svg';
import './App.css';
import Navigation from './components/Navigation';
import Root from './pages/Root';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <div class="main-header">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div id="sticky-header" class="nav-menu">
                <div class="header-logo">
                  <a href="/"><img src="assets/images/logo_biale_text.png" alt="" /></a>
                  <a class="main_sticky" href="/"><img src="assets/images/logo_biale_text.png" alt="Logo" /></a>
                </div>
                <div class="heder-menu">
                  <Navigation></Navigation>
                </div>
                {/* <div class="search-box-btn search-box-outer"><i class="fas fa-search"></i></div> */}
                <div class="header-button">
                  <a href="https://www.facebook.com/AMCompPL" target="_blank"><i class="fa-brands fa-square-facebook"></i></a>
                  <a href="https://www.instagram.com/amcomppl/" target="_blank"><i class="fa-brands fa-square-instagram"></i></a>
                  {/* <a href="" target="_blank"><i class="fa-brands fa-linkedin"></i></a> */}
                  <a href="https://github.com/adrianmarpl" target="_blank"><i class="fa-brands fa-square-github"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile-menu-area d-sm-block d-md-block d-lg-none ">
        <div class="mobile-menu">
          <nav class="itsoft_menu">
            <Navigation specialClass="nav_scroll"></Navigation>
          </nav>
        </div>
      </div>


      <Root></Root>

      <Footer></Footer>



      <div class="search-popup">
        <button class="close-search style-two"><i class="fa fa-times"></i></button>
        <button class="close-search"><i class="fas fa-arrow-up"></i></button>
        <form method="post" action="#">
          <div class="form-group">
            <input type="search" name="search-field" value="" placeholder="Search Here" required="" />
            <button type="submit"><i class="fa fa-search"></i></button>
          </div>
        </form>
      </div>

      <div class="scroll-area">
        <div class="top-wrap">
          <div class="go-top-btn-wraper">
            <div class="go-top go-top-button">
              <i class="fa fa-angle-double-up" aria-hidden="true"></i>
              <i class="fa fa-angle-double-up" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>


    </div>
  )


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
