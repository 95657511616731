
function Contact() {
    return (
        <>
            <div class="breadcumb-area d-flex align-items-center">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="breacumb-content">
                                <div class="breadcumb-title">
                                    <h1>Kontakt</h1>
                                </div>
                                <div class="breadcumb-content-text">
                                    <a href="/"> START<i class="fa fa-angle-right"></i><span>Kontakt</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="contact-area">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="single-contact-box">
                                <div class="section-title">
                                    <div class="sub-title">
                                        <h5>Skontaktuj się z nami<span></span></h5>
                                        <h2>Chcesz porozmawiać o swoim projekcie?</h2>
                                    </div>
                                </div>
                                <form action="api/sender.php" method="POST" id="dreamit-form">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form_box">
                                                <input type="text" name="name" placeholder="Imię" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form_box">
                                                <input type="text" name="email" placeholder="Email" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form_box">
                                                <input type="text" name="phone" placeholder="Telefon" />
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6">
                                            <div class="form_box">
                                                <input type="text" name="subject" placeholder="Temat" />
                                            </div>
                                        </div>
                                        <div class="col-lg-12 col-md-12">
                                            <div class="form_box">
                                                <textarea name="message" id="message" cols="30" rows="10" placeholder="Twoja wiadomość"></textarea>
                                            </div>
                                            <div class="form-button">
                                                <button type="submit">Prześlij zapytanie</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <div id="status"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>);
}

export default Contact;
